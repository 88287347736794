<template>
  <HeaderRow
      :current="headerValues.current"
      :rest="headerValues.rest"
      :complete="headerValues.complete"
  />
  <router-view
      @setheadervalues="setHeaderValues"
  />
  <FooterRow
      ref="footer"
  />
</template>

<script>
import {getQuestSlug} from './common-functions';
import HeaderRow from "@/components/quest/commonPageElements/HeaderRow.vue";
import FooterRow from "@/components/quest/commonPageElements/FooterRow.vue";

export default {
  name: 'App',
  components: {
    FooterRow,
    HeaderRow,
  },
  data () {
    return {
      questId: null,
      state: "start",
      error: false,
      headerValues: {
        current: 0,
        rest: 0,
        complete: 0,
        skipped: 0
      }
    };
  },
  mounted() {
    this.questId = getQuestSlug(this);
    console.log(this.$route, this.questId);
  },
  methods: {
    setHeaderValues(values) {
      for (let i in this.headerValues) {
        if (typeof values[i] !== 'undefined') {
          this.headerValues[i] = values[i];
        }
      }
    }
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  background-color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  position: relative;
}

.fullscreen-form {
  position: fixed;
  left: 0;
  right: 100%;
  width: 100%;
  top: 76px;
  bottom: 76px;
  background-color: white;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@font-face {
  font-family: 'motelking';
  src: url('./assets/motelking-medium.ttf')  format('truetype');
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: motelking, Avenir, Helvetica, Arial, sans-serif;
  position: fixed;
}

body {
  background-color: #00000000;
  margin: 0;
}

.font-gradient {
  background-color: #36ff00;
  background-image: linear-gradient(180deg, #f1ee21, #36ff00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content-text {
  /*width: 100%;*/
  flex: 1;
  background-color: white;
  font-family: Arial, Serif;
  text-indent: 1em;
  font-size: 20px;
  overflow-y: auto;
  box-shadow: rgb(0 0 0 / 35%) 0px -42px 20px -42px inset;
}

.content-text p {
  padding: 10px;
  color: black;
  text-align: left;
  margin-block-start: 0;
  margin-block-end: 0;
}

@media (max-width: 375px) {
  .content-text {
    font-size: 18px;
  }
}

button {
  background-color: #36ff00;
  font-size: 22px;
  font-family: motelking, Serif;
  padding: 5px 20px;
  height: 50px;
  color: #1e1e1e;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
}

button:disabled {
  background-color: #b7b7b7;
}

.SmartCaptcha-Shield {
  z-index: 5000;
}

</style>
