<template>
  <div class="fullscreen-form">
    <h1 class="header">
      Анкета победителя
      <div class="close-container"><div class="close" @click="$emit('close')"></div></div>
    </h1>

    <form id="questReviewQuestionnarie" class="form">
      <ol>
        <li
            v-for="question in questions"
            v-bind:key="question.fieldName"
            :class="invalidValueFieldNames[question.fieldName] ? 'error' : ''"
        >
          {{question.text}}
          <template v-if="question.required"> *</template>
          <br />
          <QuestionnarieRange v-if="question.type==='range'" :max="question.limit" :name="question.fieldName" />
          <QuestionnarieRange v-if="question.type==='options'" :variants="question.variants" :name="question.fieldName" />
          <textarea v-if="question.type==='text'" :name="question.fieldName" />
        </li>
      </ol>
      <button v-on:click="checkQuestionnarie">{{hasBonuses ? 'Получить бонус' : 'Отправить'}}</button>
    </form>

  </div>
</template>

<script>
import QuestionnarieRange from "@/components/quest/misc/QuestionnarieRange.vue";
import {apiCall} from "@/common-functions";

export default {
  components: {QuestionnarieRange},
  emits: ['close', 'apierror'],
  props: {
    questId: String,
    hasBonuses: Boolean
  },
  data() {
    return {
      rangeItems: 5,
      questions: [
        {
          text: 'Насколько познавательным для вас был данный квест?',
          type: 'range',
          fieldName: 'intrestingquest',
          limit: 5,
          required: true
        },
        {
          text: 'Приобрели ли вы новые знания после прохождения?',
          type: 'range',
          fieldName: 'newknowledge',
          limit: 5,
          required: true
        },
        {
          text: 'Насколько интересно вы провели время?',
          type: 'range',
          fieldName: 'intrestingtime',
          limit: 5,
          required: true
        },
        {
          text: 'Сколько человек проходили квест вместе с Вами (дети тоже в счёт)',
          type: 'text',
          fieldName: 'peopleCount',
          limit: 5,
          required: true
        },
        {
          text: 'Порекомендуете ли вы этот квест друзьям?',
          type: 'range',
          fieldName: 'recommend',
          limit: 5,
          required: true
        },
        {
          text: 'Планируете ли вы пройти другие наши маршруты?',
          type: 'options',
          fieldName: 'plan',
          variants: ['Да','Нет'],
          required: true
        },
        {
          text: 'Будете ли вы теперь более бережно относиться к памятникам культуры?',
          type: 'range',
          fieldName: 'care',
          limit: 5,
          required: true
        },
        {
          text: 'К какой категории граждан вы относитесь?',
          type: 'options',
          fieldName: 'category',
          variants: ['Молодежь, студенты','Активные пенсионеры','Школьники','Активные граждане, семьи с детьми'],
          required: true,
        },
        {
          text: 'Ваши пожелания создателям квеста',
          type: 'text',
          fieldName: 'wishes',
          limit: 5,
        },
      ],
      invalidValueFieldNames: {}
    };
  },
  methods: {
    checkQuestionnarie(e) {
      e.preventDefault();
      const formData = new FormData(window.document.getElementById('questReviewQuestionnarie'));
      const data = {};
      for (let key of formData.keys()) {
        data[key] = formData.get(key);
      }

      this.invalidValueFieldNames = {};
      console.log(data);
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].required) {
          const hasValue = typeof data[this.questions[i].fieldName] !== "undefined" && data[this.questions[i].fieldName].trim() !== '';
          console.log(this.questions[i].fieldName, data[this.questions[i].fieldName], hasValue);
          if (!hasValue) {
            this.invalidValueFieldNames[this.questions[i].fieldName] = 'Обязательное значение';
          }
        }
      }
      console.log(this.invalidValueFieldNames);

      if (Object.keys(this.invalidValueFieldNames).length === 0) {
        this.sendQuestionnarie(data);
      }
      return false;
    },

    sendQuestionnarie(data) {
      const component = this;
      apiCall('/quest/' + this.questId + '/questionnarie/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
          .then(() => {
            this.$emit('close');
          })
          .catch((e) => {
            console.error(e)
            component.$emit('apierror');
          })
      ;
    }
  },
  created() {
      console.log('this.questId',this.questId)
  }
}
</script>

<style scoped>
  .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    font-family: Helvetica, Arial;
    align-items: center;
    overflow-y: scroll;
  }

  li {
    margin-bottom: 20px;
  }

  textarea {
    width: 80%;
    min-height: 44px;
  }

  button {
    width: 80%;
  }

  .error {
    color: red;
  }

  ol {
    width: 90%;
    padding-inline-start: 20px;
  }

  .fullscreen-form {
    top: 0;
  }

  h1 {
    font-family: Helvetica, Arial;
    margin-block-start: 5px;
    margin-block-end: 5px;
    color: #36ff00;
    background-color: #333333;
    width: 100%;
    margin: 0;
    padding: 10px;
    text-align: center;
  }

</style>

