<template>
  <div class="fullscreen-form">
    <div class="header">
      {{ bonus.name }}
      <div class="description">
        {{ bonus.description }}
      </div>
      <div
          class="close-container"
      >
        <div class="close" @click="$emit('hide')"></div>
      </div>
    </div>

    <img :src="thisMakeImageUrlAbsolute(bonus.imageUrl)" />
    <div class="longDescription" v-html="thisPlaintextToHtml(bonus.longDescription)" ></div>

    <button @click="click">Использовать бонус</button>

    <QuestionDialog
        v-if="showConfirmationForCoupon !== null"
        title="Погасить бонус?"
        :text="useBonusConfirmationText()"
        :buttons="questionButtons"
        @hide="showConfirmationForCoupon = null"
        @action="useAction"
    />

    <QuestionDialog
        v-if="useResultText"
        :title="useResultHeader"
        :text="useResultText"
        :buttons="['Ок']"
        @hide="useResultText = null; $emit('used')"
        @action="useResultText = null; $emit('used')"
    />

  </div>
</template>

<script>
import {apiCall, makeImageUrlAbsolute, metrikaEvent, plaintextToHtml} from '../../../common-functions';
import QuestionDialog from "@/components/quest/commonPageElements/QuestionDialog.vue";

export default {
  components: {QuestionDialog},
  emits: ['hide'],
  props: ['bonus'],
  data() {
    return {
      authorized: false,
      feedbackString: '',
      promocodes: [],
      questionButtons: ["Да, я кассир", "Нет"],
      showConfirmationForCoupon: null,
      useResultText: null,
      useResultHeader: null,
    };
  },
  methods: {
    thisPlaintextToHtml(p) {
      return plaintextToHtml(p);
    },

    thisMakeImageUrlAbsolute(arg) {
      return makeImageUrlAbsolute(arg);
    },

    click() {
      if (this.bonus.isValid) {
        this.showConfirmationForCoupon = this.bonus;
      } else {
        this.useResultText = 'Невозможно погасить бонус, он уже не активен';
        this.useResultHeader = 'Ошибка';
      }
    },

    useBonusConfirmationText() {
      return "Гасим бонус " + this.showConfirmationForCoupon.name + ". " +
          "Данное действие выполняет только кассир. Вы уверены, что точно хотите погасить бонус? После гашения его невозможно будет использовать повторно.";
    },

    useAction(action) {
      const promocodeId = this.showConfirmationForCoupon.id;
      this.showConfirmationForCoupon = null;
      if (action !== this.questionButtons[0]) {
        return;
      }

      apiCall(
          '/promocodes/'+promocodeId+'/use',
          {
            method: 'POST'
          })
          .then(res => {
            return res.json();
          })
          .then(res => {
            if (res.error) {
              this.useResultHeader = 'Ошибка';
              this.useResultText = 'Ошибка при погашении купона';
              if (res.reason) {
                this.useResultText += ': ' + res.reason;
              }
            } else {
              this.useResultHeader = 'Ура!';
              this.useResultText = 'Бонус успешно погашен';
              metrikaEvent('bonususe-'+this.bonus.promocodeTypeId);
            }
          });
    }
  },
}
</script>

<style scoped>
  .header {
    background-color: #36ff00;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-block-start: 0;
    font-size: 26px;
    padding: 5px;
  }

  .header .description {
    font-size: 14px;
  }

  .longDescription {
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    width: calc(100% - 22px);
  }

  .close-container {
    position: absolute;
    right: 0;
    width: 40px;
  }
  .close {
    position: relative;
    width: 58px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  button {
    padding: 15px 25px;
    margin: 10px;
  }

  img {
    width: 100%;
  }

  .fullscreen-form {
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>
