import { createApp } from 'vue'
import App from './Quest.vue'
import { createWebHistory, createRouter } from "vue-router";

import Promocodes from "@/components/quest/promocodes/PromocodesWindow.vue";
import QuestContainer from "@/components/quest/QuestContainer.vue";


const routes = [
    { path: '/quest/:questId', component: QuestContainer, props: true },
    { path: '/bonus/', component: Promocodes, props:
            {
                separatePage: true
            }
    },
    // { path: "/:catchAll(.*)", component: Promocodes },
];


//redirect from old url format:
if (location.pathname === '/' && location.search.indexOf('quest=') >= 0) {
    const urlsearchparams = new URLSearchParams(window.location.search);
    const questId = urlsearchparams.get('quest');
    if (questId) {
        urlsearchparams.delete('quest');
        location.href = '/quest/' + questId + (urlsearchparams.toString().length ? '?' + urlsearchparams.toString() : '');
    }
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history'
})

const app = createApp(App);
app.use(router)
router.isReady().then(() => {
    window.vm = app.mount('#app')
});
