<template>
  <input
      v-model="answerText"
      @input="answerChanged"
      autocomplete="off"
      placeholder="введите ответ" />
</template>

<script>
export default {
  emits: ['setanswer'],
  data() {
    return {
      answerText: ''
    };
  },

  methods: {
    answerChanged () {
      this.$emit("setanswer", this.answerText);
    }
  }
}
</script>

<style scoped>
input {
  width: 100%;
  width: calc(100% - 40px);
  height: 100%;
  height: calc(100% - 40px);
  background-color: #00000000;
  border: none;
  padding: 20px;
  font-size: 20px;
}
</style>
