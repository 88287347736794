<template>
  <div class="error-dialog">
    <p>Произошла непредвиденная ошибка. Пожалуйста, обновите страницу.</p>
    <p>Если ошибка повторяется, обратитесь к разработчикам.</p>
    <button @click="reload">Обновить</button>
  </div>
</template>

<script>

export default {
  props: {
    questId: String
  },

  methods: {
    reload() {
      location.reload();
    }
  }
}
</script>

<style scoped>
.error-dialog {
  background: #ff7e7ec9;
  position: absolute;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 30px;
}

.error-dialog >>> p {
  margin: 10px;
}

button {
  background-color: #ff0000;
  color: #1e1e1e;
}
</style>