<template>
  <div :class="separatePage ? 'content' : 'fullscreen-form'">
    <h1 class="header">
      Бонусы
      <div
          v-if="! separatePage"
          class="close-container"
      >
        <div class="close" @click="$emit('hide')"></div>
      </div>
    </h1>

    <div v-if="! authorized">
      <AuthorizationComponent
          auth-prefix="quest"
          header="Для просмотра бонусов необходимо авторизоваться"
          @authorizedSuccess="authorizedSuccess"
      />
    </div>

    <div class="promocode-list" v-if="promocodes && promocodes.length">
      <div
          v-for="promocode in promocodes"
          @click="click(promocode)"
          v-bind:key="promocode.id"
          class="promocode-item"
          :class="optionClass(promocode.isValid)"
      >
        <div class="upper">
          <div class="left">
            <div class="title">{{ promocode.name }}</div>
            <div class="description">{{ promocode.description }}</div>
          </div>
          <div class="right">
            <div>скидка</div>
            <div class="percent">{{promocode.discountPercent}}%</div>
          </div>
        </div>
        <div class="bottom" v-if="bottomText(promocode.isValid, promocode.validBefore, promocode.usedAt)">
          {{bottomText(promocode.isValid, promocode.validBefore, promocode.usedAt)}}
        </div>
      </div>
    </div>

    <div v-else class="no-bonus">
      <template v-if="authorized">
        Здесь пока ничего нет. Чтобы заработать бонусы, проходите квесты.
      </template>
      <template v-else>
        Авторизуйтесь, чтобы увидеть свои бонусы.
      </template>
    </div>

    <OneBonus
        v-if="selectedBonus"
        :bonus="selectedBonus"
        :separatePage="separatePage"
        @hide="selectedBonus = null"
        @used="selectedBonus = null; loadPromocodes()"
    />

    <QuestionDialog
        v-if="useResultText"
        :title="useResultHeader"
        :text="useResultText"
        :buttons="['Ок']"
        @hide="useResultText = null"
        @action="useResultText = null"
    />
  </div>
</template>

<script>
import AuthorizationComponent from "../../authorization/AuthorizationComponent.vue";
import {apiCall, metrikaEvent} from '../../../common-functions';
import OneBonus from "@/components/quest/promocodes/OneBonus.vue";
import QuestionDialog from "@/components/quest/commonPageElements/QuestionDialog.vue";

export default {
  components: {QuestionDialog, OneBonus, AuthorizationComponent},
  emits: ['hide'],
  props: {
    separatePage: Boolean
  },
  data() {
    return {
      authorized: false,
      feedbackString: '',
      promocodes: [],
      questionButtons: ["Да, я кассир", "Нет"],
      showConfirmationForCoupon: null,
      useResultText: null,
      useResultHeader: null,
      selectedBonus: null
    };
  },
  methods: {
    loadPromocodes() {
      apiCall(
          '/promocodes/',
          {
            method: 'GET'
          })
          .then(res => {
            metrikaEvent('bonuslist-');
            return res.json();
          })
          .then(res => {
            this.promocodes = res.promocodeList;
            console.log(res);
          });
    },

    authorizedSuccess(userData) {
      console.log('Authorization data:', userData)
      this.authorized = userData;
      this.loadPromocodes();
    },

    click(coupon) {
      if (coupon.isValid) {
        this.selectedBonus = coupon;
      } else {
        this.useResultText = 'Невозможно погасить бонус, он уже не активен';
        this.useResultHeader = 'Ошибка';
      }
    },

    optionClass(isValid) {
      return isValid? 'canBeUsed' : 'cannotBeUsed';
    },

    bottomText(isValid, validBefore, usedAt) {
      const text = usedAt ? 'Использована ' : 'Действует до ';
      if (! validBefore && ! usedAt) {
        return null;
      }

      const date = new Date(usedAt ? usedAt : validBefore);
      let day = date.getUTCDate();
      day = (day < 10 ? '0' : '') + day.toString();

      let month = date.getUTCMonth() + 1;
      month = (month < 10 ? '0' : '') + month.toString();

      let dateString = day + '.' + month + '.' + date.getUTCFullYear();

      return text + dateString;
    },
  },

  mounted() {
    this.loadPromocodes();
  }
}
</script>

<style scoped>
  .header {
    background-color: #36ff00;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-block-start: 0;
  }
  .close-container {
    position: absolute;
    right: 0;
    width: 40px;
  }
  .close {
    position: relative;
    width: 58px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  button {
    margin: 10px;
  }

  .promocode-list {
    font-family: Verdana, Arial;
    padding: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .promocode-item {
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    min-height: 60px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background-color: #8d8d8d;
    color: #525252;
    cursor: pointer;
  }

  .canBeUsed {
    background-color: #00f003;
    color: unset;
  }

  .upper {
    border-bottom: solid 2px black;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    letter-spacing: 0.1em;
  }

  .upper .left {
    border-right: solid black 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 6;
    flex-direction: column;
  }
  .upper .right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
    font-weight: bolder;
    flex-direction: column;
  }

  .upper .right div {
    padding-left: 3px;
    padding-right: 3px;
  }

  .percent {
    font-size: 24px;
  }

  .title {
    font-weight: bolder;
    font-size: 22px;
    text-transform: uppercase;
  }

  .description {

  }

  .bottom {
    font-size: 16px;
  }

  .no-bonus {
    font-family: Verdana, Arial;
    margin: 20px;
    text-align: center;
    line-height: 1.5em;
  }

  .canBeUsed .bottom {
    color: red;
  }
</style>
