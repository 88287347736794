<template>
  <div class="header">
    <div class="complete font-gradient">
      <span class="number">{{ (complete ? complete : '0') }}</span><span>пройдено<br>пунктов</span>
    </div>

    <div class="current font-gradient">
      <span class="number" v-if="current && current != 0">
        {{current}}
      </span>
      <span v-if="current && current != 0">вопрос</span>

      <span class="number" v-if="!current || current == 0">&nbsp;</span>
      <span v-if="!current || current == 0">&nbsp;</span>
    </div>

    <div class="await font-gradient">
      <span>осталось<br>точек</span>
      <span class="number">{{ (rest ? rest : '0') }}</span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    complete: Number,
    current: Number,
    rest: Number
  },
}
</script>

<style scoped>
.header {
  width: 100%;
  max-height: 72px;
  background-color: #333333;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: center;
  align-content: stretch;
  border-top: 8px solid #767676;
  border-bottom: 8px solid #767676;
}

.header > div {
  width: 100%;
  position: relative;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}
/*https://webformyself.com/naklonennye-pod-odnim-uglom-kraya-v-css/*/
.header > div:nth-child(2)::after {
  border: 4px solid #767676;
  background-color: #767676;
  content: " ";
  display: block;
  height: 72px;
  position: absolute;
  transform: rotate(30deg);
  left: 2px;
  top: -10px;
  width:8px;
}

.header > div:nth-child(3)::after {
  border: 4px solid #767676;
  background-color: #767676;
  content: " ";
  display: block;
  height: 72px;
  position: absolute;
  transform: rotate(-30deg);
  left: -20px;
  top: -10px;
  width:8px;
}

.header > div span {
  display: block;
  font-size: 12px;
}

.header .complete .number {
  font-size: 30px;
  margin: 0 10px 0 10px;
}

.header .current {
  flex-direction: column;
  height: 72px;
}

.header .current .number {
  font-size: 30px;
  margin: 10px 0 9px 0;
}

.header .await {
  justify-content: flex-end;
}
.header .await span {
  text-align: right;
}
.header .await .number {
  font-size: 32px;
  margin: 0 10px 0 10px;
}

@media (max-width: 375px) {
  .header .complete .number, .header .await .number {
    font-size: 22px;
  }
  .header .await .number,.header .complete .number {
    font-size: 18px;
    margin: 0 6px 0 6px;
  }
  .header .current .number {
    font-size: 27px;
    margin: 10px 0 7px 0;
  }
  .header > div span {
    font-size: 10px;
  }
}
</style>