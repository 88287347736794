<template>
  <div class="fullscreen-form">
    <h1 class="header">
      {{ title }}
      <div class="close-container"><div class="close" @click="$emit('hide')"></div></div>
    </h1>

    <div class="text">
      {{ text }}
    </div>
    <div class="buttons">
      <button
          v-for="button in buttons"
          @click="click(button)"
          v-bind:key="buttonToObject(button)['id']"
          :style="{'background-color':buttonToObject(button)['color']}"
      >
        {{ buttonToObject(button)['text'] }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['hide', 'action'],
  props: {
    title: String,
    text: String,
    buttons: Array
  },
  data() {
    return {
      defaultColor: '#36ff00'
    };
  },
  methods: {
    click(button) {
      this.$emit('action', this.buttonToObject(button).id);
    },
    buttonToObject(button) {
      let result;
      if (typeof button === 'string') {
        result = {
          text: button,
          id: button,
          color: this.defaultColor
        };
      } else {
        result = {
          ...button
        };
        if (!button.text) {
          throw new Error('Creating button without text');
        }
        if (typeof button.id === 'undefined') {
          button.id = button.text;
        }
        if (!button.color) {
          button.color = this.defaultColor;
        }
      }
      return result;
    },
  },
}
</script>

<style scoped>
  .header {
    background-color: #36ff00;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-block-start: 0;
    font-size: 25px;
  }
  .text {
    font-family: Verdana, Arial;
    font-size: 20px;
    padding: 15px;
  }
  .close-container {
    position: absolute;
    right: 0;
    width: 40px;
  }
  .close {
    position: relative;
    width: 58px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  button {
    margin: 10px;
  }
</style>
