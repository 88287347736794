<template>
    <input
        v-model="answerText"
        @input="answerChanged"
        type="number"
        autocomplete="off"
        placeholder="ответом должно быть число" />
</template>

<script>
export default {
  emits: ['setanswer'],
  data() {
    return {
      answerText: ''
    };
  },

  methods: {
    answerChanged () {
      this.$emit("setanswer", this.answerText);
    }
  }
}
</script>

<style scoped>
input {
  width: 100%;
  width: calc(100% - 40px);
  height: 100%;
  height: calc(100% - 40px);
  background-color: #00000000;
  border: none;
  padding: 20px;
  font-size: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
