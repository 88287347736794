<template>
  <div class="content">
    <QuestInfoPage
        v-if="state === 'start'"
        :quest-id="questId"
        @startQuiz="startQuiz"
        @setheadervalues="setHeaderValues"
        @already-finished-quiz="finishedQuiz"
    />
    <QuestInProgressPage
        v-else-if="state === 'quiz'"
        :quest-id="questId"
        @setheadervalues="setHeaderValues"
        @finished-quiz="finishedQuiz"
        @apierror="apierror"
    />
    <QuestFinishedPage
        v-else-if="state === 'finished'"
        :quest-id="questId"
        :show-questionnarie="questIsCompleteStatus ? questIsCompleteStatus.showQuestionnarie : false"
        @setheadervalues="setHeaderValues"
    />
    <PromocodesWindow
        v-if="promocodesIsVisible"
        @hide="this.promocodesIsVisible = false"
    />
    <ErrorReloadPage
        v-if="error"
        :quest-id="questId"
    />
  </div>
</template>

<script>
import QuestInfoPage from "@/components/quest/QuestInfoPage.vue";
import QuestInProgressPage from "@/components/quest/QuestInProgressPage.vue";
import ErrorReloadPage from "@/components/quest/commonPageElements/ErrorReloadPage.vue";
import QuestFinishedPage from "@/components/quest/QuestFinishedPage.vue";
import PromocodesWindow from "@/components/quest/promocodes/PromocodesWindow.vue";

export default {
  emits: ['setheadervalues'],

  components: {
    PromocodesWindow,
    QuestFinishedPage,
    ErrorReloadPage,
    QuestInProgressPage,
    QuestInfoPage,
  },
  props: ["questId"],
  data () {
    return {
      state: "init",
      error: false,
      headerValues: {
        current: 0,
        rest: 0,
        complete: 0,
        skipped: 0
      },
      promocodesIsVisible: false,
      questIsCompleteStatus: {}
    };
  },
  methods: {
    finishedQuiz(questIsCompleteStatus) {
      this.state = 'finished';
      this.questIsCompleteStatus = questIsCompleteStatus;
    },
    startQuiz() {
      console.log('startQuiz');
      this.state = 'quiz';
      if (window.localStorage.getItem('helpWasShown') !== '1') {
        window.vm.$refs.footer.showHelpWindow();
      }
    },
    apierror() {
      this.error = true;
    },
    setHeaderValues(values) {
      this.$emit('setheadervalues', values);
    },
  },
  mounted() {
    if (this.questId) {
      this.state = 'start';
    }
    this.$parent.$parent.$refs.footer.addOptionItem('Бонусы', () => {
      this.promocodesIsVisible = true;
    });
  }
}
</script>
