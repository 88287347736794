<template>
  <div class="navigation-background">
    <div class="navigation">
      <div v-if="debug" class="debug">
        {{ debugMessage }}
      </div>
      <div class="point-header">{{ placeTitle }}</div>

      <div
          v-if="!! placeDescription"
          class="content-text"
          v-html="'<p>' + placeDescription.replace(/\n/g, '<p>')"
      ></div>

      <div class="instructions" v-if="myPosition === null && waitedSomeTime">
        Для правильной работы квеста геолокация на устройстве должна быть включена и у браузера должны быть соответствующие разрешения.
      </div>

      <div class="instructions" v-else-if="waitedSomeTime && (accuracy === null || accuracy > 40)">
        Низкая точность геолокации. Дождитесь, пока устройство определит ваше местоположение. Возможно, потребуется дополнительно выдать браузеру разрешение использовать точное местоположение.
      </div>

      <div class="instructions close" v-else-if="navigationType === 'compass'">
        Держите телефон горизонтально и идите по направлению стрелки
      </div>

      <div class="instructions" v-if="waitedSomeTime && isCompassUnsupported && navigationType === 'compass'">
        Похоже, навигация компасом работает неверно. Вы можете сменить вид навигации в меню в левом нижнем углу.
      </div>

      <div class="distance">
        {{ distanceDescriptionString }}
        <button @click="this.$emit('inOkRadius');" style="height: inherit;font-size: 10px;padding: 5px 5px;">Я уже на месте</button>
      </div>
      <div class="navigator-frame">
        <YandexMaps
            v-if="navigationType=='map' || supportedNavigationTypes.length <= 1"
            :geo="placeGeo"
            :myPosition="myPosition"
            :placeTitle="placeTitle"
        />
        <CompassFrame
            v-else
            :geo="placeGeo"
            :myPosition="myPosition"
            :positionOk="myPosition !== null"
            :placeTitle="placeTitle"
            @methodissupported="compassNavigationSupported"
        />
      </div>
    </div>
  </div>
</template>

<script>
import YandexMaps from './YandexMaps.vue'
import CompassFrame from './CompassFrame.vue'
import {getDistanceFromLatLonInKm, getImageUriOrDefault} from '../../../common-functions'

const navigationTypeLocalStorageVariableName = 'navigation-type-tmp1';

const timeToWaitForGeoSeconds = 5;

export default {
  props: {
    placeGeo: Array,
    okRadius: Number,
    placeTitle: String,
    placeDescription: String,
    verticalPlaceImageUrl: String,
  },
  emits: ['inOkRadius', 'skipQuestion'],
  data() {
    return {
      myPosition: null,
      accuracy: 0,
      navigationType: 'compass',
      supportedNavigationTypes: ['map', 'compass'],
      isCompassUnsupported: false,
      waitedSomeTime: false,
      debug: false,
      debugMessage: 'debug'
    };
  },

  geolocationWatch: null,

  methods: {
    calculateDistanceKm () {
      if (this.myPosition === null) {
        return null;
      }
      return getDistanceFromLatLonInKm(
          this.placeGeo[0], this.placeGeo[1],
          this.myPosition[0], this.myPosition[1]
      );
    },
    setMapView () {
      this.navigationType = 'map';
      window.localStorage.setItem(navigationTypeLocalStorageVariableName, this.navigationType);
    },
    setCompassView () {
      this.navigationType = 'compass';
      window.localStorage.setItem(navigationTypeLocalStorageVariableName, this.navigationType);
    },
    switchNavigation() {
      if (this.navigationType === 'map') {
        this.setCompassView();
      } else {
        this.setMapView();
      }
    },
    compassNavigationSupported (isCompassSupported) {
      console.log('Compass is supported =', isCompassSupported);
      this.isCompassUnsupported = ! isCompassSupported;
      // this.setMapView();
      // this.supportedNavigationTypes.splice(this.supportedNavigationTypes.indexOf('compass'), 1);
    },

    updateMyPosition(currentPosition) {
      this.myPosition = [
        currentPosition.coords.latitude,
        currentPosition.coords.longitude
      ];

      this.accuracy = currentPosition.coords.accuracy;
      console.log('updated my position',currentPosition.coords);
    }
  },

  computed: {
    distanceDescriptionString () {
      const distance = this.calculateDistanceKm();

      if (distance === null) {
        return "ищем вас...";
      }

      if (distance * 1000 < this.okRadius) {
        this.$emit('inOkRadius');
      }

      if (distance >= 5.0) {
        return "~" + Math.round(distance) + '\xa0км';
      }
      if (distance >= 2.0) {
        return "~" + Math.round(distance * 2) / 2 + '\xa0км';
      }
      return "~" + Math.round(distance * 1000) + '\xa0м';
    },

    cssImageUrl() {
      return 'url("' + getImageUriOrDefault(this.verticalPlaceImageUrl) + '")';
    }
  },

  components: {
    YandexMaps,
    CompassFrame
  },

  mounted() {
    const component = this;

    if (location.search.indexOf('debug_navigation') >= 0) {
      this.debug = true;
    }

    if (window.localStorage.getItem(navigationTypeLocalStorageVariableName)) {
      this.navigationType = window.localStorage.getItem(navigationTypeLocalStorageVariableName);
    } else {
      this.navigationType = 'compass';
    }

    const urlParams = new URLSearchParams(window.location.search);
    const autoNavigationTimeout = urlParams.get('dev-auto-navigation');
    if (autoNavigationTimeout) {
      setTimeout(() => {
        component.$emit('inOkRadius');
      }, autoNavigationTimeout * 1000);
    }

    const geolocationOptions = {
      maximumAge: 1000,
      enableHighAccuracy: true
    };

    this.debugMessage = 'setting watch listener';

    component.$options.geolocationWatch = navigator.geolocation.watchPosition(
        (currentPosition, e) => {
          if (e) {
            console.log('err watchPosition')
            console.error(e);
            this.debugMessage = 'error: ' + e.message + '<br>' + e.stack;
            return;
          }

          const cloneAsObject = (obj) => {
            if (obj === null || !(obj instanceof Object)) {
              return obj;
            }
            var temp = (obj instanceof Array) ? [] : {};
            for (var key in obj) {
              temp[key] = cloneAsObject(obj[key]);
            }
            return temp;
          };

          // todo: https://stackoverflow.com/a/65242535

          this.debugMessage = 'currentPosition: ' + JSON.stringify(cloneAsObject(currentPosition));
          component.updateMyPosition(currentPosition);

          component.accuracy = currentPosition.coords.accuracy;
          console.log(currentPosition.coords);
        },
        (positionError) => {
          console.error(positionError);
        },
        geolocationOptions
    );

    window.vm.$refs.footer.addOptionItem('Пропустить вопрос', () => {
      this.$emit('skipQuestion');
    });
    window.vm.$refs.footer.addOptionItem('Компас / карта', () => {
      this.switchNavigation();
    });

    window.navigationWaitForGeoTimeout = setTimeout(() => {
      window.navigationWaitForGeoTimeout = null;
      component.waitedSomeTime = true;
    }, timeToWaitForGeoSeconds * 1000);
  },

  beforeUnmount() {
    window.vm.$refs.footer.removeOptionItem('Пропустить вопрос');
    window.vm.$refs.footer.removeOptionItem('Компас / карта');
    this.$options &&
      this.$options.geolocationWatch &&
    navigator.geolocation.clearWatch(this.$options.geolocationWatch);

    this.debugMessage = 'cleared watch';

    this.$options && (this.$options.geolocationWatch = null);

    if (window.navigationWaitForGeoTimeout) {
      clearTimeout(window.navigationWaitForGeoTimeout);
      window.navigationWaitForGeoTimeout = null;
    }
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
html, body {
  height: 100%;
  margin: 0;
}
</style>

<style scoped>

.navigation-background {
  width: 100%;
  height: 100%;
  flex: 1;
  background-image: v-bind('cssImageUrl');
  background-repeat: no-repeat;
  background-size: cover;
}

.navigation {
  width: 100%;
  height: 100%;
  flex: 1;
  background: #ffffffad;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
}

.navigation .point-header {
  min-height: 60px;
  width: 100%;
  text-align: center;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation .content-text {
  margin: 10px;
  background-color: #ffffffdf;
  font-family: Arial, Serif;
  text-indent: 1em;
  font-size: 20px;
  overflow-y: auto;
  max-height: 30%;
}

.navigation .content-text >>> p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.navigation .instructions {
  color: red;
  text-align: center;
  padding: 10px;
}

.navigation .distance {
  width: 100%;
  font-family: motelking, Arial, sans-serif;
  text-align: center;
  font-size: 25px;
  padding: 7px 0 7px 0;
}

.navigator-frame {
  width: 100%;
  height: 100%;
  flex: 1;
}

.close:before {
  content: " ";
  position: absolute;
}

.debug {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  opacity: 0.9;
  word-break: break-all;
  background-color: yellow;
}

@media (max-width: 375px) {
  .navigation .distance {
    font-size: 18px;
  }
  .navigation .instructions {
    padding: 6px;
  }
  .navigation .point-header {
    min-height: 45px;
  }
  .navigation .content-text {
    margin: 0 10px 10px 10px;
    font-size: 18px;
  }
}


.content-text::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 20px #2c2c2cc9;
  border-radius: 10px;
  background-color: white;
}
.content-text::-webkit-scrollbar {
  width: 8px;
  height: 0;
}
.content-text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0);
}
.content-text {
  box-shadow: none;
  background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */ radial-gradient(farthest-side at 50% 0, rgb(0 0 0 / 50%), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgb(0 0 0 / 50%), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
</style>
