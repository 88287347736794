<template>
  <div class="options">
    <div class="option" v-for="option in options" @click="click" v-bind:key="option.text" :class="optionClass(option.text)">
      {{ option.text }}
    </div>
  </div>
</template>

<script>
export default {
  emits: ['setanswer'],
  props: {
    options: Array,
    invalidAnswer: String,
    correctAnswer: String
  },

  data() {
    return {
      answer: null
    };
  },

  methods: {
    click (e) {
      if (this.answer === e.target.innerText) {
        this.answer = null;
      } else {
        this.answer = e.target.innerText;
      }
      this.$emit("setanswer", this.answer);
    },

    optionClass(option) {
      if (this.answer !== 'null' && this.answer === option) {
        return 'selected';
      }
      return null;
    }
  }
}
</script>

<style scoped>
.options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.option {
  margin: 5px;
  background-color: #36ff00;
  font-size: 14px;
  font-family: motelking, Serif;
  padding: 9px 9px;
  color: #1e1e1e;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option.selected {
  color: red;
}
</style>
