<template>
  <div class="fullscreen-form">
    <div class="header">Обратная связь</div>
    <div class="info">Вы можете связаться с нами через форму ниже. Если ваш отзыв предполагает необходимость связаться с вами, не забудьте, пожалуйста, оставить свои контакты.</div>
    <textarea v-model="feedbackString"></textarea>
    <button @click="sendFeedback">Отправить</button>
    <button @click="$emit('hide')">Отмена</button>
  </div>
</template>

<script>
import {apiCall} from '../../../common-functions';

export default {
  emits: ['hide'],
  data() {
    return {
      feedbackString: ''
    };
  },
  methods: {
    sendFeedback() {
      this.feedbackString = this.feedbackString.trim();
      console.log(this.feedbackString);
      if (!this.feedbackString) {
        return;
      }

      apiCall(
          '/public/feedback/',
          {
            method: 'POST',
            body: JSON.stringify({
              message: this.feedbackString,
              userData: window.localStorage.getItem('userData')
            }),
            headers: {
              'content-type': 'application/json'
            }
          })
          .then(res => {
            return res.json();
          })
          .then(res => {
            if (res.success) {
              this.$emit('hide');
            }
          });
    },
  },
}
</script>

<style scoped>
  .header {
    background-color: #36ff00;
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .close {
    position: relative;
    width: 58px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  button {
    margin: 10px;
  }

  textarea {
    width: 100%;
    width: calc(100% - 20px);
    margin: 10px;
    flex: 1;
  }

  .info {
    font-family: Verdana, Arial;
    padding: 10px;
  }
</style>
