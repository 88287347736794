<template>
  <div class="compass">
    <div class="compass-arrow" :style="arrowStyle">
    </div>
  </div>
  <button v-if="isIOS && !buttonHidden" class="start-btn" id="startcompasbutton" @click="startCompasIOS">Включить компас</button>
</template>

<script>
let externalMyPosition = [0, 0];
let self;
let azimuth, compassCircle, compassArrow;
let eventListener = null;

let lastCompassDegree = 0;
let compassDegreeOffset = 0;

export default {
  emits: ['methodissupported'],
  props: {
    geo: Array,
    myPosition: Array,
    placeTitle: String,
    positionOk: Boolean
  },
  data() {
    return {
      degrees: 0,
      compass: null,
      isIOS: false,
      buttonHidden: false,

      startBtn: null,
      hasOrientationData: false,
      lastSupportEmittedState: null
    };
  },

  timers: {},

  mounted() {
    self = this;

    window.compassFrame = this;

    self.startBtn = document.querySelector(".start-btn");

    this.isIOS =
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/);

    if (!this.isIOS) {
      eventListener = (e) => {
        if (e.webkitCompassHeading || e.alpha) {
          this.hasOrientationData = true;
        }

        return self.handler(e);
      };

      setTimeout(() => {
        if (! self.hasOrientationData) {
          window.compassFrame.emitCompassSupport(false);
        }
      }, 5000);

      window.addEventListener("deviceorientationabsolute", eventListener, true);
    }

    this.$props.positionInterval = setInterval(() => {
      externalMyPosition = self.myPosition;
      self.calcDegree(self);
    }, 500);

  },

  computed: {
    arrowStyle() {
      return {
        display: (this.positionOk ? 'block' : 'none')
      };
    }
  },

  methods: {
    hideButton () {
      this.buttonHidden = true;
    },
    startCompasIOS () {
      window.componentCompass = this;
      setTimeout(() => {
        window.componentCompass.hideButton();
      }, 5000);

      DeviceOrientationEvent.requestPermission()
          .then((response) => {
            if (response === "granted") {
              window.addEventListener("deviceorientation", window.componentCompass.handler, true);
            } else {
              console.error("has to be allowed!");
            }
          })
          .catch(() => console.error("not supported"));

    },
    emitCompassSupport (isSupported) {
      if (window.compassFrame.lastSupportEmittedState !== isSupported) {
        window.compassFrame.$emit('methodissupported', isSupported);
        window.compassFrame.lastSupportEmittedState = isSupported;
      }

      if (isSupported) {
        window.compassFrame.buttonHidden = true;
      }
    },
    handler: (e) => {
      if (! e.webkitCompassHeading && ! e.alpha) {
        return;
      }

      if (!window.compassFrame.isIOS) {
        if (!e.absolute) {
          console.log('Direction is not absolute - compass mode is unsupported');
          window.compassFrame.emitCompassSupport( false);
        } else {
          window.compassFrame.emitCompassSupport(  true);
        }
      } else if (e.webkitCompassHeading) {
        window.compassFrame.emitCompassSupport(  true);
      }

      azimuth = e.webkitCompassHeading || Math.abs(e.alpha - 360);

      if (lastCompassDegree > 350 && azimuth < 10) {
        compassDegreeOffset += 360;
      } else if (lastCompassDegree < 10 && azimuth > 350) {
        compassDegreeOffset -= 360;
      }
      lastCompassDegree = azimuth;

      if (!compassCircle) {
        compassCircle = document.querySelector(".compass");
      }
      if (!compassArrow) {
        compassArrow = document.querySelector(".compass-arrow");
      }

      compassCircle.style.transform = `rotate(${-azimuth - compassDegreeOffset}deg)`;
      compassArrow.style.transform = `rotate(${self.pointDegree}deg)`;

      // ±15 degree
      // if (
      //     (self.pointDegree < Math.abs(compass) &&
      //         self.pointDegree + 15 > Math.abs(compass)) ||
      //     self.pointDegree > Math.abs(compass + 15) ||
      //     self.pointDegree < Math.abs(compass)
      // ) {
      //   compassArrow.style.opacity = 0;
      // } else if (self.pointDegree) {
      //   compassArrow.style.opacity = 1;
      // }
    },

    calcDegreeToPoint: (latitude, longitude) => {
      const point = {
        lat: self.geo[0],
        lng: self.geo[1]
      };

      const phiK = (point.lat * Math.PI) / 180.0;
      const lambdaK = (point.lng * Math.PI) / 180.0;
      const phi = (latitude * Math.PI) / 180.0;
      const lambda = (longitude * Math.PI) / 180.0;
      const psi =
          (180.0 / Math.PI) *
          Math.atan2(
              Math.sin(lambdaK - lambda),
              Math.cos(phi) * Math.tan(phiK) -
              Math.sin(phi) * Math.cos(lambdaK - lambda)
          );
      return Math.round(psi);
    },

    calcDegree: (self) => {
      if (! externalMyPosition) {
        return;
      }
      self.pointDegree = self.calcDegreeToPoint(externalMyPosition[0], externalMyPosition[1]);

      if (self.pointDegree < 0) {
        self.pointDegree = self.pointDegree + 360;
      }
    }

  },

  beforeUnmount() {
    this.$props.positionInterval && clearInterval(this.$props.positionInterval);
    if (eventListener) {
      window.removeEventListener('deviceorientationabsolute', eventListener, true);
      eventListener = null;
    }
    compassCircle = null;
    compassArrow = null;
  }
}
</script>

<style scoped>
.compass {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  margin: 15px;
  background-image: url(@/assets/compass.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compass-arrow {
  width: 60%;
  height: 60%;
  background-image: url(@/assets/direction.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
}

button {
  position: fixed;
  bottom: 90px;
  left: 0;
  min-height: 40px;
  z-index: 1500;
}

@media (max-width: 375px) {
  .compass {
    margin: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
  }
}
</style>