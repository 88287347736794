<template>
  <template v-for="index in max ? parseInt(max) : variants" :key="index">
    <span>
      <input type="radio" :id="(idPrefix ? idPrefix : name) + '-' + index" :name="name" :value="index" />
      <label :for="(idPrefix ? idPrefix : name) + '-' + index">{{index}}</label>
    </span>
  </template>
</template>

<script>
export default {
  props: {
    max: Number,
    name: String,
    idPrefix: String,
    variants: Array
  }
}
</script>

<style scoped>
span {
  white-space: nowrap;
}
</style>