<template>
  <div class="footer-position">
    <div class="footer">
      <div class="menu-button" @click="toggleMenu">
        <div></div><div></div><div></div>
      </div>
      <a href="https://artquestwalk.ru/" class="site-name font-gradient">Арт-квест прогулка</a>
    </div>
  </div>
  <FooterMenu
    v-if="menuIsVisible"
    @hidemenu="toggleMenu"
    @showhelpwindow="showHelpWindow"
    @showfeedback="showFeedbackWindow"
    :itemNames="itemNames"
    :itemCallbacks="itemCallbacks"
    ref="menu"
  />
  <HelpWindow
      v-if="helpIsShown"
      @hide="hideHelpWindow"
  />
  <FeedbackForm
      v-if="feedbackFormIsShown"
      @hide="hideFeedbackWindow"
  />
</template>

<script>

import FooterMenu from "@/components/quest/commonPageElements/FooterMenu.vue";
import HelpWindow from "@/components/quest/commonPageElements/HelpWindow.vue";
import FeedbackForm from "@/components/quest/commonPageElements/FeedbackForm.vue";

export default {
  components: {FeedbackForm, HelpWindow, FooterMenu},
  data() {
    return {
      menuIsVisible: false,
      itemNames: [],
      itemCallbacks: [],
      helpIsShown: false,
      feedbackFormIsShown: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuIsVisible = ! this.menuIsVisible;
    },
    clearOptionList() {
      this.itemNames = [];
      this.itemCallbacks = [];
    },
    addOptionItem(optionName, callback) {
      if (this.itemNames.indexOf(optionName) < 0) {
        this.itemNames.push(optionName);
        this.itemCallbacks.push(callback);
      } else {
        const index = this.itemNames.indexOf(optionName);
        this.itemCallbacks[index] = callback;
      }
    },
    removeOptionItem(optionName) {
      const index = this.itemNames.indexOf(optionName);
      if (index >= 0) {
        this.itemNames.splice(index, 0);
        this.itemCallbacks.splice(index, 0);
      }
    },
    showHelpWindow() {
      this.hideFeedbackWindow();
      this.helpIsShown = true;
    },
    hideHelpWindow() {
      this.helpIsShown = false;
      window.localStorage.setItem('helpWasShown', '1');
    },
    showFeedbackWindow() {
      this.feedbackFormIsShown = true;
    },
    hideFeedbackWindow() {
      this.hideHelpWindow();
      this.feedbackFormIsShown = false;
    }
  }
}
</script>

<style scoped>
.footer-position {
  width: 100%;
  height: 72px;
  background-color: #333333;
  overflow: hidden;
  bottom: 0;

  border-top: 8px solid #767676;
  border-bottom: 8px solid #767676;

}

.footer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.footer .menu-button {
  width: 100px;
  height: 100%;
}

.footer > .site-name::after {
  border: 4px solid #767676;
  background-color: #767676;
  content: " ";
  display: block;
  height: 150px;
  position: absolute;
  transform: rotate(30deg);
  left: -16px;
  top: -40px;
  width: 8px;
}

.footer .site-name {
  width: 100%;
  text-align: center;
  position: relative;
  font-size: 16px;
  white-space: nowrap;
  display: block;
}

.footer .menu-button {
  position: relative;
  cursor: pointer;

  background-color: #36ff00;
  background-image: linear-gradient(180deg, #f1ee21, #36ff00);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.footer .menu-button div {
  width: 60px;
  min-height: 6px;
  background: transparent;
  border-top: 6px #333333 solid;
  border-bottom: 0 #333333 solid;
  border-left: 12px #333333 solid;
  border-right: 17px #333333 solid;
}

.footer .menu-button div:first-child {
  border-top: 17px #333333 solid;
}
.footer .menu-button div:nth-child(2) {
  border-right: 29px #333333 solid;
  width: 48px;
}
.footer .menu-button div:last-child {
  border-bottom: 17px #333333 solid;
  width: 36px;
  border-right: 40px #333333 solid;
}

@media (max-width: 375px) {
  .footer .site-name {
    text-align: right;
    font-size: 13px;
    margin-right: 28px;
  }
}

@media (max-width: 320px) {
  .footer .site-name {
    margin-right: 6px;
  }
}
</style>