<template>
  <div class="scrolled-info">
    <div v-if="state === 'loading'" class="image-placeholder animated-background"></div>
    <div v-else class="image"></div>

    <div v-if="state === 'loading'" class="quest-name-placeholder animated-background"></div>
    <div v-else  class="quest-name"><div class="font-gradient">{{quizData.title}}</div></div>

    <div v-if="state === 'loading'" class="content-text-placeholder animated-background"></div>
    <div class="content-text" v-html="'<p>' + quizData.introText.replace(/\n/g, '<p>')"></div>
  </div>

  <div v-if="state !== 'loading'" class="content-footer">
    <div v-if="! authorized">
      <AuthorizationComponent
          auth-prefix="quest"
          @authorizedSuccess="authorizedSuccess"
      />
    </div>
    <button
        v-else
        v-on:click="startQuiz"
    >
      {{quizData.startButtonText}}
    </button>
  </div>
</template>

<script>
import AuthorizationComponent from "../authorization/AuthorizationComponent.vue";
import {apiCall, getImageUriOrDefault, metrikaEvent} from "@/common-functions";

export default {
  components: {
    AuthorizationComponent,
  },
  props: {
    questId: String,
  },
  emits: ['startQuiz', 'alreadyFinishedQuiz', 'setheadervalues'],
  data() {
    return {
      state: 'loading',
      authorized: false,
      quizData: {
        titleColor: null,
        startImageUrl: null,
        introText: '',
      },
      hasActiveQuestSession: null
    };
  },

  computed: {
    cssImageUrl() {
      return 'url("' + getImageUriOrDefault(this.quizData.startImageUrl) + '")';
    }
  },

  methods: {
    startQuiz() {
      const self = this;
      if (self.hasActiveQuestSession === false) {
        apiCall('/quest/' + this.questId + '/startSession', {method: 'POST'})
            .then(response => {
              if (response.ok) {
                metrikaEvent('startquest-'+this.questId);
                return response.json();
              }
              throw new Error('Error starting quest session');
            })
            .then(() => {
              metrikaEvent('resumequest-'+this.questId);
              self.hasActiveQuestSession = true;
              this.startQuiz();
            })
      } else if (self.hasActiveQuestSession !== null) {
        metrikaEvent('resumequest-'+this.questId);
        this.$emit('startQuiz');
      }
    },
    authorizedSuccess(userData) {
      console.log('Authorization data:', userData)
      this.authorized = userData;
      this.checkHasActiveSession();
    },
    checkHasActiveSession() {
      const self = this;
      apiCall('/quest/' + this.questId + '/getActiveSession')
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Error fetching quest sessions');
          })
          .then(json => {
            self.hasActiveQuestSession = json.sessionIsStarted;
            if (json.allQuestionsAnswered) {
              this.$emit('alreadyFinishedQuiz');
            } else if (self.hasActiveQuestSession) {
              this.startQuiz();
            }
          });
    }
  },
  created() {
    apiCall('/public/quest/' + this.questId)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Error fetching quest');
        })
        .then(json => {
          console.log(json);
          this.quizData = json;
          this.state = 'loaded';

          this.$emit('setheadervalues', {
            rest: json.questionCount
          });

          metrikaEvent('questinfo-'+this.questId);
        })
        .catch(e => {
          metrikaEvent('error-questinfo-'+this.questId);
          console.error(e);
          //todo catch 404
        });
  }
}
</script>

<style scoped>
@import '@/scss/loading-animation.scss';

.image {
  width: 100%;
  flex: 1;
  background-image: v-bind('cssImageUrl');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 45%;
}

.image-placeholder {
  width: 100%;
  flex: 1;
  background-size: cover;
}

.quest-name {
  min-height: 60px;
  background-color: #333333;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 26px;
  text-align: center;
  line-height: 1.2;
}

.content-footer {
  width: 100%;
  height: 72px;
  background-color: #333333;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.content-text {
  overflow-y: visible;
}

.scrolled-info {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

@media (max-width: 375px) {
  .quest-name {
    font-size: 22px;
  }
  button {
    font-size: 18px;
  }
  .content-footer {
    height: 45px;
  }
}

</style>