<!--https://yandex.ru/dev/maps/jsbox/2.1/event_properties-->
<!--https://yandex.ru/dev/maps/jsapi/doc/2.1/ref/reference/MapEvent.html-->
<!--https://yandex.ru/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-boundschange-->
<template>
  <div id="map" style="width:100%;height: 100%"></div>
</template>

<script>
import {getDistanceFromLatLonInKm} from '../../../common-functions'

export default {
  props: {
    geo: Array,
    myPosition: Array,
    placeName: String
  },
  data() {
    return {
      settings: {
        apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY, // Индивидуальный ключ API
        lang: 'ru_RU', // Используемый язык
        coordorder: 'latlong', // Порядок задания географических координат
        debug: false, // Режим отладки
        version: '2.1' // Версия Я.Карт
      },
      autoscale: true
    };
  },

  timers: {},

  mounted() {
    const component = this;

    let myMap, myPositionPlacemark=null;

    const initMap = () => {
      let lastBounds = [[0, 0], [0, 0]];

      window.ymaps.ready(function () {
        myMap = new window.ymaps.Map('map', {
          center: component.geo,
          zoom: 19,
          controls: []
        });

        const placePlacemark = new window.ymaps.Placemark(component.geo, {
          hintContent: component.placeName,
          balloonContent: component.placeName
        }, {
          // Необходимо указать данный тип макета.
          iconLayout: 'default#image',
          // Своё изображение иконки метки.
          iconImageHref: '/flag1.png',
          // Размеры метки.
          iconImageSize: [22, 42],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [-11, -35]
        });

        let mousePressed = false;
        let isNear = false;

        myMap.geoObjects
            .add(placePlacemark);
        component.$options.timers.updateMyPosition = setInterval(() => {
          if (component.myPosition !== null) {
            if (myPositionPlacemark === null) {
              myPositionPlacemark = new window.ymaps.Placemark(component.myPosition, {
                hintContent: 'Ваше местоположение',
                balloonContent: 'Ваше местоположение'
              }, {
                // Опции.
                // Необходимо указать данный тип макета.
                iconLayout: 'default#image',
                // Своё изображение иконки метки.
                iconImageHref: '/traveler1.png',
                // Размеры метки.
                iconImageSize: [24, 54],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-12, -54]
              });
              myMap.geoObjects.add(myPositionPlacemark);
            } else {
                myPositionPlacemark.geometry.setCoordinates(component.myPosition);
            }

            if (component.autoscale) {
              const distanceMeters = getDistanceFromLatLonInKm(
                  component.myPosition[0],
                  component.myPosition[1],
                  component.geo[0],
                  component.geo[1],
              ) * 1000;

              if (!isNear) {
                const xmin = Math.min(component.myPosition[0], component.geo[0]);
                const ymin = Math.min(component.myPosition[1], component.geo[1]);
                const xmax = Math.max(component.myPosition[0], component.geo[0]);
                const ymax = Math.max(component.myPosition[1], component.geo[1]);

                const xbounds = 0.2 * Math.abs(xmax - xmin);
                const ybounds = 0.2 * Math.abs(ymax - ymin);

                const newBounds = [
                  [xmin - xbounds, ymin - ybounds],
                  [xmax + xbounds, ymax + ybounds]
                ];

                if (JSON.stringify(lastBounds) !== JSON.stringify(newBounds)) {
                  lastBounds = newBounds;
                  myMap.setBounds(lastBounds);
                }
              }

              isNear = distanceMeters < 80;
            }
          }
        }, 1000);

        myMap.events.add('mousemove', () => {
          if (mousePressed) {
            component.autoscale = false;
          }
        })
        myMap.events.add('mousedown', () => {
          mousePressed = true;
        });
        myMap.events.add('mouseup', () => {
          mousePressed = false;
        });
        myMap.events.add('boundschange', () => {
          if (mousePressed) {
            component.autoscale = false;
          }
        });
      });
    }

    if (typeof window.ymaps === 'undefined') {
      const mapsScript = document.createElement('script')
      mapsScript.setAttribute('src', 'https://api-maps.yandex.ru/' + this.settings.version + '/?lang=' + this.settings.lang +'&apikey=' + this.settings.apiKey)
      document.head.appendChild(mapsScript)

      mapsScript.addEventListener('load', function() {
        window.ymaps.ready(initMap);
      });
    } else {
      initMap();
    }
  },

  beforeUnmount() {
    this.$options.timers.updateMyPosition && clearInterval(this.$options.timers.updateMyPosition);
    this.$options.timers.updateMyPosition = null;
  }
}
</script>
