<template>
  <div class="fullscreen-form">

    <h1>Помощь
      <div class="close-container"><div class="close" @click="$emit('hide')"></div></div>
    </h1>
    <img src="/help/img1.png" />
    <h2>Как играть:</h2>
    <ol class="info">
      <li>
        Зайдите в игру (пройдите регистрацию, включите геолокацию и дайте все разрешения браузеру).
      </li>
      <li>
        Дождитесь, когда телефон спозиционируется и покажет расстояние до точки.
      </li>
      <li>
        Держите телефон горизонтально. Красная стрелка на компасе покажет направление движения.
        В случае недостаточно точной работы компаса в вашем телефоне вы можете переключиться на навигацию при помощи
        карты. Переключатель находится в меню в левом нижнем углу.
      </li>
      <li>
        Двигайтесь к точке (расстояние будет уменьшаться). Во время движения почитайте информацию об объекте,
        к которому вы идёте.
        <img src="/help/img2.png" />
      </li>
      <li>
        Когда подойдёте к объекту, описание исчезнет и появится вопрос квеста.
      </li>
      <li>
        Введите ответ на вопрос. Если ответ верный – приступайте к следующей точке.
        Если ответ неверный, можно попробовать еще раз ввести ответ.
        Количество попыток не ограничено.
      </li>
      <li>
        Вопросы можно пропускать. В случае пропуска вопроса награждение за прохождение квеста не начисляется.
      </li>
      <li>
        Игру можно приостановить, просто закрыв браузер. В следующий раз можно будет продолжить игру с этого же места.
      </li>
      <li>
        В конце игры Вас ждут небольшие призы за прохождение маршрута.
      </li>
    </ol>
    <button @click="$emit('hide')">Ок</button>
    <img src="/help/img3.png" />
  </div>
</template>

<script>
export default {
  emits: ['hide'],
  data() {
    return {
      feedbackString: ''
    };
  },
}
</script>

<style scoped>
  h1 {
    background-color: #36ff00;
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;

    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding: 6px;
  }

  h2 {
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 18px;
    font-family: Verdana, Arial;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  img {
    width: 100%;
  }

  .close-container {
    position: absolute;
    right: 0;
    width: 40px;
  }

  .close {
    position: relative;
    width: 58px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .fullscreen-form {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .info {
    font-family: Verdana, Arial;
    padding-right: 5px;
  }
</style>
