<template>
  <div class="navigation-background">
    <div class="navigation">
      <div class="point-header">{{ placeTitle }}</div>
      <div class="text"
           :class="resultClass"
           v-html="thisPlaintextToHtml(answerDescription)"
      >
      </div>
      <div class="text longDescription"
           v-html="thisPlaintextToHtml(longDescription)"
      >
      </div>
      <button v-if="!this.answeredCorrectly" @click="$emit('retryQuestion')">Попробовать еще</button>

      <button :class="!this.answeredCorrectly ? 'skip' : ''" @click="$emit('nextQuestion')">{{ nextButtonText }}</button>
    </div>
  </div>
</template>

<script>

import {getImageUriOrDefault, plaintextToHtml} from "@/common-functions";

export default {
  methods: {
    thisPlaintextToHtml(arg) {
      return plaintextToHtml(arg);
    }
  },
  props: {
    placeTitle: String,
    verticalPlaceImageUrl: String,
    answeredCorrectly: Boolean,
    answerDescription: String,
    longDescription: String,
    nextButtonText: String,
  },
  emits: ['finishedQuiz', 'nextQuestion', 'retryQuestion'],
  data() {
    return {
    };
  },

  computed: {
    cssImageUrl() {
      return 'url("' + getImageUriOrDefault(this.verticalPlaceImageUrl) + '")';
    },
    resultClass() {
      return this.answeredCorrectly ? 'success' : 'fail';
    }
  }
}
</script>

<style scoped>
.navigation-background {
  width: 100%;
  height: 100%;
  flex: 1;
  background-image: v-bind('cssImageUrl');
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
}

.navigation {
  width: 100%;
  height: 100%;
  flex: 1;
  background: #ffffffad;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation .point-header {
  min-height: 60px;
  width: 100%;
  text-align: center;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text::before {
  content: " ";
  display: block;
  width: 100%;
  height: 30%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.text.longDescription >>> p {
  font-size: 13px;
}

.success::before {
  background-image: url(@/assets/ok-tick.png);
}

.fail::before {
  background-image: url(@/assets/fail-cross.png);
}

.text >>> p {
  text-align: center;
  font-size: 22px;
  margin-block-end: 0.3em;
  margin-block-start: 0.3em;
}

button {
  font-size: 17px;
  margin-bottom: 60px;
  width: 90%;
}

.skip {
  background-color: red;
}

</style>
