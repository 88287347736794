export function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1);
    var a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c; // Distance in km
}

function deg2rad(deg) {
    return deg * (Math.PI/180)
}

export function apiCall(url, options) {
    const isEditor = document.location.pathname.startsWith('/editor');
    const localStoragePrefix = isEditor ? 'editor_' : 'quest_';
    const additionalHeaders = {};

    if (window.localStorage.getItem(localStoragePrefix + 'authToken') && window.localStorage.getItem(localStoragePrefix + 'validBefore') && window.localStorage.getItem(localStoragePrefix + 'validBefore') <= new Date()) {
        additionalHeaders.authorization = window.localStorage.getItem(localStoragePrefix + 'authToken');
    }

    if (Object.keys(additionalHeaders).length > 0) {
        if (options === null || typeof options === 'undefined') {
            options = {};
        }
        options = {...options};
        if (typeof options.headers === 'undefined') {
            options.headers = {};
        }
        options.headers = {
            ...options.headers,
            ...additionalHeaders
        }
    }
    const apiPrefix = isEditor ? process.env.VUE_APP_EDITOR_API_URL : process.env.VUE_APP_API_URL;
    console.log('URL:', apiPrefix + url);
    return fetch(apiPrefix + url, options);
}

export function isAuthorized() {
    const isEditor = document.location.pathname.startsWith('/editor');
    const localStoragePrefix = isEditor ? 'editor_' : 'quest_';
    return !!window.localStorage.getItem(localStoragePrefix + 'authToken');
}

export function logout() {
    const isEditor = document.location.pathname.startsWith('/editor');
    const localStoragePrefix = isEditor ? 'editor_' : 'quest_';
    window.localStorage.removeItem(localStoragePrefix + 'authToken');
    window.localStorage.removeItem(localStoragePrefix + 'validBefore');
    window.localStorage.removeItem(localStoragePrefix + 'userData');
}

export function getImageUriOrDefault(cssImageUrl) {
    return makeImageUrlAbsolute(cssImageUrl ? cssImageUrl : process.env.BASE_URL + 'rotonda-vertical.jpg');
}

export function getQuestSlug(component) {
    if (component.$route && component.$route.params.questslug) {
        return component.$route.params.questslug;
    } else {
        return new URLSearchParams(window.location.search).get('quest')
    }
}

export function metrikaEvent(eventName) {
    console.log('metrika reachGoal', eventName);
    window.ym(93659296,'reachGoal',eventName);
}

export function makeImageUrlAbsolute(imageFilename) {
    if (imageFilename && (imageFilename.indexOf('/quest-img') >= 0 || imageFilename.indexOf('images') === 0)) {
        return process.env.VUE_APP_S3_URL_PREFIX + imageFilename.replace('/quest-img','images');
    }
    else {
        return imageFilename;
    }
}

export function plaintextToHtml(plaintext) {
    if (typeof plaintext === 'undefined' || plaintext === null) {
        return '';
    }
    return '<p>' + plaintext.replace(/\n/g, '<p>');
}

export function getGeoIndex(fieldName) {
    if (fieldName.toLowerCase().indexOf('lon') >= 0) {
        return 1;
    }
    if (fieldName.toLowerCase().indexOf('lat') >= 0) {
        return 0;
    }
    throw new Error('Invalid geo field name');
}