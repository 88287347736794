<template>
  <div
      class="menu-background"
      @click="$emit('hidemenu')"
  ></div>
  <div class="footer-menu">
    <li v-for="item in itemNames" v-bind:key="item" @click="clickItem">{{ item }}</li>
    <li @click="showHelpWindow()">Помощь</li>
    <li @click="openSite">Открыть сайт</li>
    <li @click="showFeedbackForm()">Обратная связь</li>
    <li @click="logout" v-if="isAuthorized">Выход</li>
  </div>
</template>

<script>

import {isAuthorized, logout} from "@/common-functions";

export default {
  emits: ['hidemenu', 'showhelpwindow', 'showfeedback'],
  props: {
    itemNames: Array,
    itemCallbacks: Array,
  },
  data() {
    return {
      isAuthorized: false
    };
  },
  methods: {
    openSite() {
      window.open('https://artquestwalk.ru/', '_blank', 'noreferrer');
    },
    showHelpWindow() {
      this.$emit('showhelpwindow');
      this.$emit('hidemenu');
    },
    showFeedbackForm() {
      this.$emit('showfeedback');
      this.$emit('hidemenu');
    },
    logout() {
      logout();
      window.location.reload();
    },
    clickItem(e) {
      const index = this.itemNames.indexOf(e.target.innerText);
      if (index >= 0) {
        this.itemCallbacks[index]();
      }
      this.$emit('hidemenu');
    }
  },
  created() {
    this.isAuthorized = isAuthorized();
  }
}
</script>

<style scoped>
.menu-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}
.footer-menu {
  bottom: 76px;
  left: 0;
  position: fixed;
  background-color: #333333f2;
  font-family: Verdana, Arial;
  font-size: 24px;
  font-weight: bold;
  z-index: 3000;
}

.footer-menu li {
  margin: 25px;
  list-style-type: none;
  color: #36ff00;
  cursor: pointer;
}
</style>